.new__orders {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 16px;
    gap: 20px;
}

.new__orders .header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}

.items__price__header__grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.new__orders .more {
    color: #A9A9A9;
}

.new__order__row {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    justify-content: center;
    align-items: center;
}

.items__price__grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: center;
    align-items: center;
}

.new__order__row .items__count {
    display: none;
}

.new__order__row img {
    width: 30px;
    height: 30px;
}

.new__order__row .more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    color: #53B175;
}

@media (max-width: 576px) {
    .new__orders .header,
    .new__order__row {
        grid-template-columns: 1fr 2fr 1fr;
    }

    .items__price__header__grid,
    .items__price__grid {
        grid-template-columns: 1fr 1fr;
    }

    .items__price__grid img,
    .items__price__grid .dot{
        display: none;
    }
    
    .new__order__row .items__count {
        display: flex;
    }
}

@media (max-width: 350px) {
    .new__orders .header,
    .new__order__row {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .items__price__grid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .price__header {
        display: none;
    }
}