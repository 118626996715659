.sidebar {
    width: 18%;
    min-height: 100vh;
    border-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sidebar .logo {
    width: 45px;
    object-fit: contain;
}

.sidebar span {
    text-align: center;
}

.sidebar__options {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar__option {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 12px;
    cursor: pointer;
}

.sidebar__options a {
    text-decoration: none;
    color: #212245;
    font-size: 0.8rem;
}

.sidebar__option.active {
    background-color: #53B17526;
    border-left: 2px solid #53B175;
}

@media (max-width: 992px) {
    .sidebar {
        width: 46px;
    }

    .sidebar__option span {
        display: none;
    }
}


@media (max-width: 768px) {
    .sidebar .logo {
        width: 35px;
        object-fit: contain;
    }

    .sidebar span {
        font-size: 0.8rem;
    }
}