.products {
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 16px;
}

.products .addProduct__btn {
    border: none;
    padding: 7px 15px;
    background: #53B175;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
}