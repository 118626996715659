.product__list .sorting__widget{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 7px 15px;
    border: 1px solid #53b17526;
    border-radius: 5px;
    cursor: pointer;
}

.product__list .sorting__widget select{
    width: 100%;
    border: none;
    background-color: transparent;
    -webkit-appearance: none; 
    color: #212245;
}

.product__list .sorting__widget select:focus {
    outline: none;
}

.product__list .search__widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 7px 15px;
    border: 1px solid #53b17526;
    border-radius: 5px;
    cursor: pointer;
}

.product__list .search__widget input {
    border: none;
    background-color: transparent;
}

.product__list .search__widget input:focus {
    outline: none;
}

.product__list .list__content {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
}

.product__items__title {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
}

.product__items__title .product {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product__items__title .product .category {
    background: #53b17526;
    color: #212245;
    font-weight: 500;
    column-gap: 1rem;
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
}

.product__items__title span {
    color: #212245;
    font-size: 1rem;
}

.product__items__item {
    margin: 10px 0;
    color: #212245;
}

.product__items__item img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.product__items__item .action__icon {
    width: 20px;
    height: 20px;
}

@media (max-width: 576px) {
    .product__list .list__content,
    .product__items__title {
        grid-template-columns: 1fr 2fr 1fr;
    }
    
    .product__list .list__content .image,
    .product__list .list__content .in_stock,
    .product__items__title img,
    .product__items__title .in__stock__switch {
        display: none;
    }
}