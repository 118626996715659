/* google fonts */

@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web&display=swap");

/* reset default style */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* base style */

body {
  background: #fcfcfc;
  font-family: "Istok Web", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "RocknRoll One", sans-serif !important;
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #212245;
}

section {
  padding: 60px 0px;
}

.app-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;