.order__analytics {
    display: flex;
    gap: 20px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 16px;
}

.order__analytics .data__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.order__analytics .data__row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.order__analytics .data__row .icon {
    border: 1px solid #A9A9A9;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
}

.order__analytics .data__row .title {
    color: #696969;
    font-size: 0.9rem;
}

.order__analytics .data__row .data {
    color: #212245;
    font-size: 1.1rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .order__analytics {
        display: flex;
        flex-direction: column;
    }

    .order__analytics .data__column {
        width: 100%;
    }
}