.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .profile {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: white;
    background-color: #53B175;
    display: grid;
    align-content: center;
    justify-content: center;
    transition-duration: .5s;
}