.add__product__form span {
    color: #696969;
}

.add__product__form .maximum__images__note {
    font-size: 0.8rem;
}

.add__product__form .img__upload .image__area {
    width: 120px;
    height: 120px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image__area .action__buttons {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #696969;
    width: 25px;
    height: 25px;
}

.image__area .delete__icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add__product__form .img__upload .upload__area {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    border: 1px dotted #c5c5c5;
}

.add__product__form .img__upload .upload__area img {
    width: 120px;
    object-fit: contain;
}

.add__product__form .img__upload .image__area img {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.add__product__form input,
.add__product__form textarea {
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline-color: #53B175;
}

.add__product__form .category select{
    width: 30%;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    -webkit-appearance: none; 
    color: #212245;
}

.add__product__form .category select:focus {
    outline: none;
}

.add__product__form .add__category__btn {
    border: none;
    padding: 7px 15px;
    background: #53B175;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
}

.add__product__form .add__btn {
    width: 100px;
    border: none;
    padding: 7px 15px;
    background: #53B175;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
}

.add__product__form .cancel__btn {
    width: 100px;
    border: 1px solid #696969;
    padding: 7px 15px;
    background: #FFF;
    color: #696969;
    border-radius: 5px;
    font-size: 0.9rem;
}