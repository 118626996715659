.dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 16px;
}

@media (max-width: 768px) {
    .dashboard {
        display: flex;
        flex-direction: row;
    }
}