.live__orders {
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 16px;
}

.live__orders__container {
    display: flex;
    gap: 20px;
}

.total__orders, .total__pending, .total__dispatch {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #FFF;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
}

.orders__background {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.number__of__orders {
    font-size: 1.4rem;
    font-weight: 600;
}

.total__orders .title,
.total__pending .title,
.total__dispatch .title {
    color: #696969;
}

@media (max-width: 768px) {
    .total__orders, .total__pending, .total__dispatch {
        padding: 10px;
        gap: 15px;
    }

    .orders__background {
        width: 35px;
        height: 35px;
    }

    .number__of__orders {
        font-size: 1.2rem;
    }

    .total__orders .title,
    .total__pending .title,
    .total__dispatch .title {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .live__orders__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}