.popular__food {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 16px;
}


.popular__food .color__indicator {
    width: 10px;
    height: 10px;
}