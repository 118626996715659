.progress__analytics {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    padding: 20px;
    border-radius: 16px;
    justify-content: center;
}

.progress__analytics .status {
    color: #53B175;
    font-weight: 500;
    font-size: 0.9rem;
}

.progress__analytics .rate {
    color: #53B175;
    padding-top: 16px;
    font-size: 0.8rem;
}

.progress__analytics .number {
    padding-top: 8px;
    font-size: 1.2rem;
    font-weight: 600;
}

.progress__analytics button {
    padding: 3px 8px;
    background-color: #53B17526;
    color: #212245;
    border: none;
    font-size: 0.9rem;
    border-radius: 5px;
}

@media (max-width: 992px) {
    .progress__analytics {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .progress__analytics {
        padding: 15px 30px;
        width: 100%;
        margin-top: 20px;
    }
}