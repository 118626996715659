.order__history {
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 16px;
}

.order__history .tabs .tab__active {
    color: #53B175;
    border-bottom: 2px solid #53B175;
}

.order__history .list__title {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    background-color: #FFF;
    padding: 5px 15px;
}

.list__title .payment {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.order__history .order__row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    background-color: #FFF;
    padding: 15px;
}

.order__row .payment__detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.order__history .order__row .status__dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order__history .order__row .status__inner__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.order__row a {
    text-decoration: none;
    color: #212245;
}

@media (max-width: 768px) {
    .order__history .list__title,
    .order__history .order__row {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .list__title .payment,
    .order__row .payment__detail {
        grid-template-columns: 1fr;
    }

    .list__title .payment .total {
        display: none;
    }

    .order__row .payment__detail {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .order__history .list__title,
    .order__history .order__row {
        grid-template-columns: 1fr 2fr;
    }

    .list__title .name,
    .order__row .name,
    .list__title .payment,
    .order__row .payment__detail,
    .order__history .order__row .status__dot {
        display: none;
    }
}